import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import { SEO } from "../components/seo"
import IdxHeadings from "../components/sg-headings"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChess, faBookOpen, faMagnifyingGlassChart } from "@fortawesome/free-solid-svg-icons"
import Reactmarkdown from "react-markdown"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import Cta from "../components/call_to_action"



// Add Page Head component and pass in SEO variables from GraphQL
export const Head = ({ data }) => {
  const title = data.strapiIndex.headline 
  const description = data.strapiGlobal.siteDescription 
  return (
    <SEO title={title} description={description} />
  )
}

// Assign image and text variables
export default function IndexPage ({ data })
{
 
const homeImage = getImage(data.strapiIndex.homeImage.localFile)
const background = getImage(data.strapiIndex.heroImage.localFile)
const indexBody = data.strapiIndex.body.data.body
const oneHeadline = data.strapiIndex.oneHeadline
const twoHeadline = data.strapiIndex.twoHeadline
const threeHeadline = data.strapiIndex.threeHeadline
const featureOne = data.strapiIndex.featureOne
const featureTwo = data.strapiIndex.featureTwo
const featureThree = data.strapiIndex.featureThree
const oneCta = data.strapiIndex.oneCTA
const twoCta = data.strapiIndex.twoCTA
const threeCta = data.strapiIndex.threeCTA
const headshot = getImage(data.strapiTestimonial.headshot.localFile)
const heroHeadline = data.strapiIndex.headline
const heroDesc = data.strapiGlobal.siteDescription
const brands = data.allStrapiBrand.nodes

// return the layout 
  return (
    <Layout>
      <div className="relative w-full h-full bg-gradient-to-r from-gray-800 to-red-700">
            <BgImage 
              image={background} 
              alt="Smoke drifting right" 
              className="absolute h-96 bg-center object-cover mix-blend-overlay" />
                <div className="container absolute top-0 left-1/2 -translate-x-1/2">
                  <IdxHeadings title={heroHeadline} description={heroDesc} />
                </div>  
      </div>
      <div className="py-16">  
       <div className="container m-auto px-6 text-gray-600 md:px-12 xl:px-6">
          <div className="space-y-6 md:space-y-0 md:flex md:gap-6 lg:items-center lg:gap-12">
            <div className="md:5/12 lg:w-5/12">
              <GatsbyImage className="rounded-xl" image={homeImage} alt="CDP AI book" /></div>
                <div className="md:7/12 lg:w-6/12">
                  <h2 className="p-3 text-2xl text-gray-900 font-bold md:text-4xl">Join Steve Things And Get A Free White Paper</h2>
                  <div className="p-4 space-y-4 prose"><Reactmarkdown>{indexBody}</Reactmarkdown></div>
                  <div className="p-4">
                  <a href="#cta" className="bg-gray-800 text-center text-white py-3 px-4 rounded-xl focus:ring focus:ring-blue-300 hover:bg-red-400">
                     Get the paper
                  </a> 
                  </div>
          </div>
        </div>
      </div>
    </div>
    <div className="container text-2xl pt-4 text-brandgreen font-semibold text-center md:text-4xl">Things I Can Help With</div>
    <div className="container pb-6 gap-2 grid md:grid-cols-3 pt-6 m-auto px-6 text-gray-600 md:px-12 xl:px-6">
        <div className="p-4 text-center rounded-xl shadow-md">
            <FontAwesomeIcon icon={faChess} className="text-3xl text-red-400" />
            <div className="text-xl text-center">{oneHeadline}</div>
            <div className="p-4 text-center  text-cyan-900 ">{featureOne}</div>
            <button id="strategy_button" type="submit" className="bg-gray-800 text-center text-white py-2 px-4 rounded-xl focus:ring focus:ring-blue-300 hover:bg-red-400">
                     <Link to="/service/">{oneCta}</Link>
            </button>        
        </div>
        <div className="p-4 text-center rounded-xl shadow-md">
            <FontAwesomeIcon icon={faMagnifyingGlassChart} className="text-3xl text-red-400"/>
            <div className="text-xl text-center">{twoHeadline}</div>
            <div className="p-4 text-center">{featureTwo}</div>
            <button id="product_button" type="submit" className="bg-gray-800 text-center text-white py-2 px-4 rounded-xl focus:ring focus:ring-blue-300 hover:bg-red-400">
                     <Link to="/services/fractional-analytics/">{twoCta}</Link>
            </button>  
        </div>
        
        <div className="p-4 text-center rounded-xl shadow-md">
            <FontAwesomeIcon icon={faBookOpen} className="text-3xl text-red-400" />
            <div className="text-xl text-center">{threeHeadline}</div>
            <div className="p-4  text-center">{featureThree}</div>
            <button id="fractional_button" type="submit" className="bg-gray-800 text-center text-white py-2 px-4 rounded-xl focus:ring focus:ring-blue-300 hover:bg-red-400">
                     <Link to="/products/">{threeCta}</Link>
            </button> 
        </div> 
    </div>
    <div className="max-w-5xl px-4 py-8 mx-auto">
      <section className="p-8 bg-gray-200 rounded-lg">
        <div className="grid grid-cols-1 gap-12 sm:grid-cols-3 sm:items-center">
          <div className="relative">
            <div className="aspect-w-1 aspect-h-1">
              <GatsbyImage className="object-cover rounded-lg"
                image={headshot}
                alt="Scott Sphar"
              />
            </div>
          </div>

          <blockquote className="sm:col-span-2">
            <div className="text-sm space-y-4 font-medium sm:text-sm"><Reactmarkdown>
              {data.strapiTestimonial.body.data.body}
              </Reactmarkdown>
            </div>

            <cite className="inline-flex items-center mt-8 not-italic">
              <span className="hidden w-6 h-1 bg-gray-400 sm:inline-block"></span>
              <div className="text-sm text-gray-500 uppercase sm:ml-3">
                <strong><Reactmarkdown>{data.strapiTestimonial.name}</Reactmarkdown></strong> {data.strapiTestimonial.title}
              </div>
            </cite>
          </blockquote>
        </div>
      </section>
    </div>
    <div className="container text-2xl pt-4 text-brandgreen font-semibold text-center md:text-4xl">Brands I've worked with or consulted</div>
      <div className="container grid grid-cols-2 md:grid-cols-4 gap-4 pb-6">
      {brands.map(brand => (
      <figure key={brand.id} className="container mt-12 bg-white shadow-md rounded-2xl pt-2 hover:shadow-none">
            <a href={brand.url}>
            <GatsbyImage className="h-auto" 
             image={brand.logo.localFile.childImageSharp.gatsbyImageData}
              alt="brand logo" />
            </a>
      </figure>
      ))}
      </div>
      <div id="cta">
        <Cta />
      </div>
    </Layout>
  )
}

// get the data with GrapQL

export const query = graphql`
  query{
    strapiIndex {
      body {
        data {
          body
        }
      }
      homeImage {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      headline
      featureOne
      featureTwo
      featureThree
      oneHeadline
      oneCTA
      twoHeadline
      twoCTA
      threeHeadline
      threeCTA
      heroImage {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    strapiGlobal {
      siteDescription
    }
    strapiTestimonial(strapi_id: {eq: 2}) {
      headshot {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      name
      title
      body {
        data {
          body
        }
      }
    }
    allStrapiBrand {
      nodes {
        brandName
        id
        logo {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        url
      }
    }
  }
  `

  

 
